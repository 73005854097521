import { useEffect, useState } from 'react';
import { DocumentsUpload } from '../../../../chat-with-documents/DocumentsUpload';
import { KnowledgeDocument } from '../../custom-kpl-configure/common/knowledge-document/KnowledgeDocument';
import { ACCEPTED_FILE_LIST_IN_CUSTOM_KPL } from '../../../../../../constants';
import { useCustomKplContext } from '../../../../../../contexts';
import { CommonMessage } from '../../../../message';

type IkplFileUploader = {
    files: File[];
    handleUpload: Function;
    isFileUploaded: boolean;
    hasFileLimit?: boolean;
    hasFileSizeLimit?: boolean;
    maxFileSize?: number;
    maxFileCount?: number;
    showDocumentToggles?: boolean;
    acceptedFiles?: string;
};

export const KplFileUploader = ({
    files,
    handleUpload,
    isFileUploaded,
    hasFileLimit = false,
    hasFileSizeLimit = false,
    maxFileSize = 100,
    maxFileCount = 200,
    showDocumentToggles = true,
    acceptedFiles = ACCEPTED_FILE_LIST_IN_CUSTOM_KPL,
}: IkplFileUploader) => {
    const [warningMessage, setWarningMessage] = useState<string>('');
    const [isFileLimitationValid, setIsFileLimitationValid] = useState<boolean>(true);

    const { failedFileUploads } = useCustomKplContext();

    useEffect(() => {
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);

        if (hasFileSizeLimit && totalSize > maxFileSize * 1024 * 1024) {
            setWarningMessage(`Total file size exceeds the maximum limit of ${maxFileSize} MB.`);
            setIsFileLimitationValid(false);
        } else if (hasFileLimit && files.length > maxFileCount) {
            setWarningMessage(`You can only upload a maximum of ${maxFileCount} files.`);
            setIsFileLimitationValid(false);
        } else {
            setWarningMessage('');
            setIsFileLimitationValid(true);
        }
    }, [files]);

    return isFileUploaded ? (
        <div className="w-full flex flex-col gap-y-2 duration-300 animate-fade-up animate-once">
            {files.map((file, i) => (
                <KnowledgeDocument name={file?.name} key={i} fileSize={file?.size} isHideClose={true} />
            ))}
        </div>
    ) : (
        <div className="flex flex-col gap-y-2">
            {failedFileUploads.length > 0 && (
                <CommonMessage
                    isUser
                    disableCopy
                    text=""
                    content={
                        <div className="bg-red-600 py-4 rounded-lg">
                            <p className="text-sm">
                                It seems the files you uploaded didn’t upload successfully. To proceed with the KPL
                                creation, please re-upload the files and ensure they meet the required format and size.
                            </p>
                            <p className="text-yellow-700 font-semibold mt-2">Failed File Uploads</p>
                            <ul className="list-disc list-inside mt-1">
                                {failedFileUploads.map((file, index) => (
                                    <li key={index} className="text-sm text-yellow-600">
                                        <i className="fas fa-upload mr-2 text-yellow-500"></i>
                                        <i className="text-xs text-R-600">{file}</i>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                />
            )}
            <CommonMessage
                isUser
                disableCopy
                text=""
                content={
                    <DocumentsUpload
                        onClickUpload={handleUpload}
                        isFileLimitationValid={isFileLimitationValid}
                        warningMessage={warningMessage}
                        showDocumentToggles={showDocumentToggles}
                        initialLabel="Ingest to KPL"
                        postLabel="Documents ingested"
                        acceptedFiles={acceptedFiles}
                    />
                }
            />
        </div>
    );
};
